<template>
  <v-dialog ref="dialog" fullscreen v-model="dialog" persistent>
    <v-card id="dialogCard" ref="dialogCard">
      <Header :video="content.headerVideo" />
      <v-container style="max-width: 1200px">
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <Location v-if="studio && studio.openingHours" :studio="studio" />
          </v-col>
          <v-col cols="12" md="6">
            <OpeningHours
              v-if="studio && studio.openingHours"
              :selectedStudio="studio"
            />
            <PopularTimes v-if="studio && studio.code" :studio="studio" />
          </v-col>

          <v-col cols="12">
            <PlansAndPricing v-if="studio && studio._id" :studio="studio" />
          </v-col>

          <v-col cols="12">
            <InfoSection :content="content.clubSection" />
          </v-col>
        </v-row>
      </v-container>
      <CoursePlan :studio="studio.code" />
      <v-container style="max-width: 1200px">
        <v-row no-gutters>
          <v-col cols="12">
            <offers-and-services
              :offersAndServices="offersAndServices"
            ></offers-and-services>
          </v-col>
        </v-row>
      </v-container>

      <v-container style="max-width: 1200px">
        <EmployeeSection
          v-if="studio && studio.code"
          :selectedStudio="studio"
        />
      </v-container>

      <ElevenDays />
      <Footer @scrollTop="scrollTop" />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex"
import Header from "@/components/club/Header.vue"
import OpeningHours from "@/components/club/OpeningHours.vue"
import Location from "@/components/club/Location.vue"
import OffersAndServices from "@/components/club/OffersAndServices.vue"
import ElevenDays from "@/components/offers/ElevenDays.vue"
import InfoSection from "@/components/club/InfoSection.vue"
import EmployeeSection from "@/components/club/EmployeeSection.vue"
import PlansAndPricing from "@/components/club/PlansAndPricing.vue"
import PopularTimes from "@/components/club/PopularTimes.vue"
import Footer from "@/components/layout/Footer"
import CoursePlan from "@/components/groupFitness/CoursePlan"

export default {
  beforeRouteEnter(to, from, next) {
    let name = to.params.id.charAt(0).toUpperCase() + to.params.id.slice(1)

    if (to.params.id === "stgallenwest") name = "St. Gallen West"
    if (to.params.id === "stgallenost") name = "St. Gallen Ost"
    if (to.params.id === "stgalleneinstein") name = "St. Gallen Einstein"

    let type = "well come FIT"

    if (to.params.id === "winterthur") type = "winti FIT"
    if (to.params.id === "pfungen") type = "winti FIT"

    document.title = name + " – Dein " + type + " Fitnessstudio"

    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Finde dein Fitnessstudio in " +
          name +
          " bei " +
          type +
          ". Erreiche deine Fitnessziele mit unseren Kursen, modernsten Geräten und erstklassiger Betreuung. "
      )
    }
    next()
  },
  components: {
    PopularTimes,
    Header,
    OpeningHours,
    Location,
    ElevenDays,
    Footer,
    InfoSection,
    PlansAndPricing,
    EmployeeSection,
    CoursePlan,
    OffersAndServices,
  },
  data() {
    return {
      dialog: true,
      content: {
        headerVideo: null,
        clubSection: {
          pictures: null,
          text: "",
        },
      },
      offersAndServices: [],
    }
  },
  computed: {
    ...mapState({
      studios: (state) => state.studios,
      loadingStudios: (state) => state.loadingStudios,
      loadingStudiosError: (state) => state.loadingStudiosError,
    }),
    studio() {
      const id = this.$route.params.id
      return this.studios.find((studio) => studio.code === id) || {}
    },
  },
  async created() {
    await this.fetchStudios()
    this.fetchLocation()
    this.fetchOffersAndServices()
  },
  methods: {
    ...mapActions(["fetchStudios"]),
    async fetchOffersAndServices() {
      try {
        let content = await this.$axios.get(
          "https://app.wellcomefit.ch/api/website/offers-and-services/" +
            this.studio._id
        )
        if (content.status === 200 && content.data) {
          this.offersAndServices = content.data
        }
      } catch (e) {
        console.log("Fehler beim fetchen der Webseiten Infos", e)
      }
    },
    async fetchLocation() {
      try {
        let content = await this.$axios.get(
          "https://app.wellcomefit.ch/api/website/content/" + this.studio.code
        )
        if (content.status === 200 && content.data && content.data.result) {
          this.content = content.data.result
        }
      } catch (e) {
        console.log("Fehler beim fetchen der Webseiten Infos", e)
      }
    },
    closeDialog() {
      this.dialog = false
      this.$router.push("/clubs") // Redirect to a general route after closing
    },
    scrollTop() {
      const dialogCard = this.$refs.dialogCard.$el

      if (dialogCard) {
        dialogCard.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }
    },
  },
  watch: {
    "$route.params.id": "fetchLocation",
  },
}
</script>
