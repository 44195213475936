<template>
  <v-app-bar
    style="z-index: 10000"
    :class="{ 'elevation-0': transparentNavbar }"
    app
    :color="getNavbarColor()"
  >
    <!-- Linker Teil mit Burger-Icon -->
    <div style="width: 110px" class="d-flex justify-start drawerTrigger">
      <v-app-bar-nav-icon
        @click.stop="$emit('triggerDrawer')"
      ></v-app-bar-nav-icon>
    </div>

    <v-row class="drawerButtons">
      <v-col
        style="padding-left: 100px"
        cols="6"
        class="d-flex justify-space-evenly"
      >
        <v-btn @click="$router.push('/')" text>home</v-btn>
        <v-btn @click="$router.push('/clubs')" text>unsere clubs</v-btn>
        <v-btn @click="$router.push('/jobs')" text>Jobs</v-btn>
        <v-btn @click="openShop" text>shop</v-btn>
      </v-col>
      <v-col
        style="padding-right: 100px"
        cols="6"
        class="d-flex justify-space-evenly"
      >
        <v-btn @click="$router.push('/kontakt')" text>Kontakt</v-btn>
        <v-btn @click="$router.push('/about')" text>Über Uns</v-btn>
        <v-btn @click="freeWorkout" text>Kostenlos trainieren</v-btn>
      </v-col>
    </v-row>

    <!-- Platzhalter für das Logo -->
    <div
      style="
        z-index: 0;
        position: absolute;
        top: 13x;
        left: calc(50vw - 40px);
        width: 80px;
        cursor: pointer;
      "
    >
      <v-img
        v-if="!hideLogo"
        @click="$router.push('/')"
        :src="logo"
        height="40"
        contain
      ></v-img>
    </div>

    <!-- Rechter Teil mit "Join Us"-Button -->
    <div style="position: absolute; right: 15px; top: 13px">
      <v-btn
        class="font-weight-bold"
        v-if="showAlibeyNavbarButton"
        @click="openAlibeyInRoody"
        variant="flat"
        color="blue"
      >
        Buchen
      </v-btn>
      <v-btn v-else @click="openRoodyContracts" variant="flat" color="primary"
        >Abo</v-btn
      >
    </div>
  </v-app-bar>
</template>

<script>
import { ref } from "vue"
import logo from "@/assets/logo.png"

export default {
  name: "Navbar",
  data() {
    return {
      logo,
    }
  },
  methods: {
    freeWorkout() {
      let elevenDaysSites = ["home", "clubPageView"]
      if (elevenDaysSites.includes(this.$route.name)) {
        this.$router.push({
          query: { ...this.$route.query, "gratis-training": true },
        })
      } else {
        // Query-Parameter extrahieren und `gratis-training` hinzufügen
        const updatedQuery = { ...this.$route.query, "gratis-training": true }
        // Zur Startseite navigieren und die Query-Parameter anhängen
        this.$router.push({ path: "/", query: updatedQuery })
      }
    },
    getNavbarColor() {
      if (this.transparentNavbar) return "transparent"
      return "#313131"
    },
    openRoodyContracts() {
      window.open("https://app.wellcomefit.ch/contract", "__blank")
    },
    openShop() {
      window.open("https://app.wellcomefit.ch/shop", "__blank")
    },
    openAlibeyInRoody() {
      window.open("https://app.wellcomefit.ch/alibey", "__blank")
    },
  },
  computed: {
    showAlibeyNavbarButton() {
      return this.$route?.meta?.showAlibeyNavbarButton || false
    },
    hideLogo() {
      return this.$route?.meta?.hideNavbarLogo || false
    },
    transparentNavbar() {
      return this.$route?.meta?.transparentNavbar || false
    },
  },
}
</script>

<style lang="scss" scoped>
.drawerButtons {
  @media only screen and (max-width: 999px) {
    display: none !important;
  }
}
.drawerTrigger {
  @media only screen and (min-width: 1000px) {
    display: none !important;
  }
}
.v-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.custom-white-text {
  color: white !important;
}
</style>
